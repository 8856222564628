<template>
<div>
  <v-fab-transition>
    <v-btn color="grey darken-3" class="stemp-time-runner my-4" v-if="isTimerRunning && enabledTimer" depressed @click="$router.push('/stemp')" fab dark top right>{{ time }}</v-btn>
  </v-fab-transition>
</div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import TimerMixin from '../mixins/timer'
export default {
  mixins: [TimerMixin],
  data () {
    return {
      time: '00:00:00',
      webWorkerInstance: null,
      isTimerRunning: false,
      shifts: [],
      stopEmit: true,
      currentDate: moment().format('DD.MM.YYYY'),
      currentDateTotalRunningHours: null,
      exeedDate: null,
      currentDateTotal: null,
      startDatetotalHours: null,
      timerObj: {},
      enabledTimer: false
    }
  },
  computed: {
    ...mapGetters(['getuserDetails', 'generalSettings', 'getTimerObj'])
  },
  async created () {
    await this.getCurrentDayHours()
  },
  mounted () {
    this.$root.$on('stempTimerActive', (data) => {
      if (data && data.stemp && data.stemp.enabled === 1) this.enabledTimer = true
      else this.enabledTimer = false
    })
    this.timerObj = this.getTimerObj
    if (!this.isTimerRunning) {
      localStorage.removeItem('shiftValue')
    }
    this.getRunningStemp()
    this.$root.$on('continueExceed', data => {
      if (data) this.stopEmit = false
    })
    // this.$root.$on('limitOfHours', data => {
    //   if (data) this.time = '08:00:00'
    // })
    // let isRunning = window.localStorage.getItem('is_timer_running') || 'false'
    // if (isRunning === 'true') this.getRunningStemp()
  },
  watch: {
    'currentDateTotal' (val) {
      if (this.startDatetotalHours) {
        var n = moment(this.timerObj.start_at, 'DD.MM.YYYY HH:mm:ss').toDate()
        n.setSeconds(+val * 60 * 60)
        this.time = n.toTimeString().slice(0, val)
      }
    },
    'time' (val) {
      // var runningHours = this.time.split(':')
      // runningHours = Number(runningHours[0]) + (Number(runningHours[1]) + Number(runningHours[2] / 60)) / 60
      // this.currentDateTotalRunningHours = Number(this.currentDateTotal) + runningHours
      // const remainingTime = (24.00 - Number(this.currentDateTotal)).toFixed(2)
      // const runningTime = (this.currentDateTotalRunningHours - Number(this.currentDateTotal)).toFixed(2)
      // if (runningTime > remainingTime) {
      //   var decimalTimeString = remainingTime
      //   var n = new Date(0, 0)
      //   n.setSeconds(+decimalTimeString * 60 * 60)
      //   this.time = n.toTimeString().slice(0, 8)
      //   if (this.webWorkerInstance) this.webWorkerInstance.terminate()
      //   this.webWorkerInstance = null
      //   this.updatePhasuedTime()
      // }
      // if (this.currentDateTotalRunningHours > 24) {
      //   if (this.webWorkerInstance) this.webWorkerInstance.terminate()
      //   this.webWorkerInstance = null
      //   this.updatePhasuedTime()
      // }
      if (this.$store.state.common.notifiedCount === 0) this.$eventBus.$emit('checkHoursExceeding')
      if (this.stopEmit) this.$root.$emit('timer', val)
    }
  },
  methods: {
    getCurrentDayHours (date = moment().format('DD.MM.YYYY')) {
      if (date !== moment().format('DD.MM.YYYY')) this.startDatetotalHours = true
      const start = moment(date, 'DD.MM.YYYY').format('MM.DD.YYYY')
      const end = moment(date, 'DD.MM.YYYY').format('MM.DD.YYYY 23:59:59')
      const model = { start: start, end: end, users: [this.getuserDetails.id], showAllProjects: true }
      this.$api.execute('post', 'hours/filter', model).then((response) => {
        var hoursList = response.data
        let totalOfWork = 0
        let totalOfAbsense = 0
        for (var i = 0; i < hoursList.length; i++) {
          if (hoursList[i].type === 'working') {
            totalOfWork += hoursList[i].amount - hoursList[i].break_hr
          }
          if (hoursList[i].type === 'non_working') {
            totalOfAbsense += hoursList[i].amount
          }
          if (!isNaN(hoursList[i].break_hr)) {
            totalOfAbsense += hoursList[i].break_hr
          }
          this.currentDateTotal = (totalOfWork + totalOfAbsense).toFixed(2)
        }
        // if (this.currentDateTotal >= 24) {
        //   this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.hourExceedNotification' })
        // }
      })
    },
    updatePhasuedTime () {
      if (this.timerObj) {
        this.timerObj.user_id = this.timerObj.user_id ? this.timerObj.user_id : this.getuserDetails.id
        const model = this.$formatter.cloneVariable(this.timerObj)
        if (this.generalSettings.pause_setting_type !== 3) model.break_hr = 0
        model.start_at = model.start_at ? model.start_at : moment().format('YYYY-MM-DDTHH:mm:ss')
        model.paused_at = model.paused_at ? model.paused_at : moment().format('YYYY-MM-DD HH:mm:ss')
        model.break_hr = moment.duration(parseFloat(model.break_hr), 'minutes').asHours()
        const url = `stamptimes/update/${model.id}`
        const method = model.id ? 'put' : 'post'
        this.$api.execute(method, url, model).then(response => {
          this.$store.commit('setTimerObj', response.data)
          this.exeedDate = moment(response.data.start_at, 'DD.MM.YYYY HH.mm.ss').format('DD.MM.YYYY')
          this.getCurrentDayHours(this.exeedDate)
        })
        // const msg = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
        // this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: msg })
        // this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.hourExceedNotification' })
      }
    }
  }
}
</script>
<style>
.stemp-time-runner {
  border-radius: 2px;
  padding: 5px 10px;
  height: auto !important;
  width: auto !important;
  top: 5px !important;
  right: 5px !important;
}
</style>
